import { createI18n } from 'vue-i18n'
import fr from '@/i18n/fr-fr'
import en from '@/i18n/en-gb'

export default createI18n({
  locale: (navigator.language || '').split('-')[0],
  fallbackLocale: 'en',
  messages: {
    en,
    fr
  }
})
