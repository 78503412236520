export default {
  anonymous: 'Anonymous',
  ask: 'An advisor wants to join your session, accept?',
  expired: 'This session has expired.',
  incompatible: 'Your device does not support this feature.',
  no: 'No',
  start_call: 'Start audio call',
  start_screensharing: 'Start screensharing',
  start_videocall: 'Launch videoconferencing',
  title: 'ChayAll Session',
  yes: 'Oui'
}
