export default {
  anonymous: 'Anonyme',
  ask: 'Un conseiller souhaite rejoindre la session, accepter ?',
  expired: 'Cette session a expirée.',
  incompatible: 'Votre appareil ne prend pas en charge cette fonctionnalité.',
  no: 'Non',
  start_call: 'Démarrer l\'appel audio',
  start_screensharing: 'Commencer le partage d\'écran',
  start_videocall: 'Démarrer la visioconférence',
  title: 'Session ChayAll',
  yes: 'Oui'
}
