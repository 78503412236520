<template>
  <div class="confirm">
    <div>
      {{ content }}
    </div>
    <div>
      <button type="button" @click="$emit('nocallback')">
        {{ $t('no') }}
      </button>
      <button type="button" @click="$emit('yescallback')">
        {{ $t('yes') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    content: String
  }
})

export default class Confirm extends Vue {
  content!: string
}
</script>

<style lang="scss" scoped>
.confirm {
  background-color: white;
  border: 1px solid #425466;
  border-radius: 8px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  overflow: hidden;

  & > div {
    padding: 1rem;

    &:last-child {
      padding: .75rem 1rem .25rem;
      text-align: right;
      border-top: 1px solid #efefef;

      button {
        background-color: #efefef;
        border: 1px solid darken($color: #efefef, $amount: 20%);
        border-radius: 5px;
        color: black;
        font-weight: 600;
        margin-bottom: .5rem;
        padding: .25rem 1.5rem;
        transition: background-color .15s;

        &:last-child {
          background-color: #425466;
          border-color: darken($color: #425466, $amount: 20%);
          color: white;
        }

        &:not(:first-child) {
          margin-left: .5rem
        }

        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
}

</style>
