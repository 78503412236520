<template>
  <div class="webrtc" v-if="isReady" @touchend="toggleOverlay()" :class="{ 'show-overlay': showOverlay}">
    <div class="video local-media" v-show="localMedia">
      <account-icon class="icon" v-if="!isCameraEnabled"/>
      <video ref="localMediaEl" autoplay playsinline muted/>
    </div>

    <div class="viewers-medias">
      <div>
        <div class="video" v-for="viewer in viewers" :key="viewer.id"
          v-show="viewer && viewer.userMedia && viewer.userMedia !== $refs.localDisplayMediaEl.srcObject">
          <face-agent-icon class="icon" v-if="!viewer.isCameraEnabled"/>
          <video autoplay playsinline :ref="viewer.id"
            :muted="viewer && viewer.userMedia && viewer.userMedia === $refs.localDisplayMediaEl.srcObject"/>
        </div>
      </div>
    </div>

    <div class="video screensharing" v-show="localDisplayMedia || (viewers && viewers.length)">
      <face-agent-icon class="icon"
        v-if="viewers[0] && !viewers[0].isCameraEnabled && (!$refs.localDisplayMediaEl.srcObject || viewers[0].userMedia === $refs.localDisplayMediaEl.srcObject)"/>
      <video ref="localDisplayMediaEl" autoplay playsinline/>
    </div>

    <transition name="fade" v-if="localDisplayMedia && (!viewers[0] || viewers[0].userMedia !== $refs.localDisplayMediaEl.srcObject)">
      <div class="ping" ref="ping" v-show="pingTimeout"/>
    </transition>

    <div class="viewers" v-show="signaling">
      <span class="viewers-count">
        <eye-icon class="icon"/>
        {{ viewers.length }}
      </span>
      <!-- <span class="viewers-list">
        {{ viewers.map(v => v.name).join() }}
      </span> -->
    </div>

    <transition-group name="list" tag="div" class="notifications">
      <confirm v-for="notif in notifications" :key="notif.id"
        :content="$t('ask', [notif.content])"
        @nocallback="denyViewer(notif.id)"
        @yescallback="allowViewer(notif.id, notif.content)"/>
    </transition-group>

    <div class="webrtc-create" v-if="!askingPermissions && !signaling">
      <button v-if="hasUserMediaAPI && !isKindVideocall && !isKindScreensharing"
        @click="createSession(SESSION_KIND.CALL)"
        @touchend.stop
        type="button">
        <call-icon class="icon"/>
        {{ $t('start_call') }}
      </button>
      <button v-if="hasUserMediaAPI && !isKindAudiocall && !isKindScreensharing"
        @click="createSession(SESSION_KIND.VIDEOCALL)"
        @touchend.stop
        type="button">
        <video-call-icon class="icon"/>
        {{ $t('start_videocall') }}
      </button>
      <button v-if="hasMediaDeviceAPI && !isKindAudiocall && !isKindVideocall"
        @click="createSession(SESSION_KIND.SCREENSHARE)"
        @touchend.stop
        type="button">
        <play-icon class="icon"/>
        {{ $t('start_screensharing') }}
      </button>
    </div>

    <template v-else>
      <button @click="destroySession" @touchend.stop="toggleOverlay(true)"
        type="button" class="webrtc-destroy">
        <close-icon/>
      </button>
      <div class="webrtc-toggles" v-if="!displayMediaId">
        <button @click="toggleMicrophone" @touchend.stop="toggleOverlay(true)"
          type="button" class="webrtc-microphone">
          <microphone-icon v-if="isMicrophoneEnabled" class="icon"/>
          <microphone-off-icon v-else class="icon"/>
        </button>
        <button @click="toggleCamera" @touchend.stop="toggleOverlay(true)" v-show="!isKindAudiocall"
          type="button" class="webrtc-camera">
          <video-icon v-if="isCameraEnabled" class="icon"/>
          <video-off-icon v-else class="icon"/>
        </button>
        <button @click="switchCamera" @touchend.stop="toggleOverlay(true)" v-show="!isKindAudiocall && hasFacingMode"
          :class="{ hidden: !isCameraEnabled }"
          type="button" class="webrtc-camera-switch">
          <camera-switch-icon class="icon"/>
        </button>
      </div>
    </template>
  </div>

  <div v-else>
    <span class="error">
      {{ $t(isExpired ? 'expired' : 'incompatible') }}
    </span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import AccountIcon from 'vue-material-design-icons/Account.vue'
import CallIcon from 'vue-material-design-icons/PhoneOutline.vue'
import CameraSwitchIcon from 'vue-material-design-icons/CameraSwitch.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import Confirm from '@/components/Confirm.vue'
import EyeIcon from 'vue-material-design-icons/Eye.vue'
import FaceAgentIcon from 'vue-material-design-icons/FaceAgent.vue'
import MicrophoneIcon from 'vue-material-design-icons/Microphone.vue'
import MicrophoneOffIcon from 'vue-material-design-icons/MicrophoneOff.vue'
import PlayIcon from 'vue-material-design-icons/MonitorShare.vue'
import VideoCallIcon from 'vue-material-design-icons/VideoOutline.vue'
import VideoIcon from 'vue-material-design-icons/Video.vue'
import VideoOffIcon from 'vue-material-design-icons/VideoOff.vue'

const MESSAGE_TYPE = {
  ASK: 'ASK',
  CANDIDATE: 'CANDIDATE',
  PING: 'PING',
  SDP: 'SDP'
}

enum SESSION_KIND {
  CALL = 'CALL',
  SCREENSHARE = 'SCREENSHARE',
  VIDEOCALL = 'VIDEOCALL'
}

interface Viewer {
  id: string
  isCameraEnabled: boolean
  name: string
  pc: RTCPeerConnection
  userMedia?: MediaStream
}

@Options({
  components: {
    CallIcon,
    CameraSwitchIcon,
    CloseIcon,
    Confirm,
    EyeIcon,
    FaceAgentIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    AccountIcon,
    PlayIcon,
    VideoCallIcon,
    VideoIcon,
    VideoOffIcon
  }
})
export default class WebRTC extends Vue {
  private askingPermissions = false
  private displayMediaId: string | null = null
  private hasFacingMode = false
  private localMedia: MediaStream | null = null
  private localDisplayMedia: MediaStream | null = null
  private notifications: Array<{ content: string, id: string }> = []
  private pingTimeout = 0
  private showOverlay = false
  private showOverlayTimeout = 0
  private signaling: EventSource | null = null
  private userMediaEnabled: Record<string, boolean | string> = {
    audio: false,
    video: false
  }

  private viewers: Array<Viewer> = []

  get SESSION_KIND (): typeof SESSION_KIND {
    return SESSION_KIND
  }

  get isCameraEnabled (): boolean {
    return this.isUserMediaEnabled('video')
  }

  get isDeviceCompatible (): boolean {
    if (this.isKindScreensharing) {
      return this.hasMediaDeviceAPI
    } else if (this.isKindAudiocall || this.isKindVideocall) {
      return this.hasUserMediaAPI
    } else {
      return this.hasMediaDeviceAPI || this.hasUserMediaAPI
    }
  }

  get isExpired (): boolean {
    const date = this.URLSearchParams.get('expires-at')

    if (date) {
      const expirationDate = new Date(date)
      const nowDate = new Date()
      return expirationDate.getTime() - nowDate.getTime() <= 0
    }

    return false
  }

  get isKindAudiocall (): boolean {
    return this.kind === 'audiocall'
  }

  get isKindScreensharing (): boolean {
    return this.kind === 'screensharing'
  }

  get isKindVideocall (): boolean {
    return this.kind === 'videocall'
  }

  get isMicrophoneEnabled (): boolean {
    return this.isUserMediaEnabled('audio')
  }

  get isReady (): boolean {
    return this.token !== null && !this.isExpired && this.isDeviceCompatible
  }

  get kind (): string | null {
    return this.URLSearchParams.get('kind')
  }

  get token (): string | null {
    return this.URLSearchParams.get('token')
  }

  get URLSearchParams (): URLSearchParams {
    return new URLSearchParams(window.location.search)
  }

  get hasMediaDeviceAPI (): boolean {
    return 'mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices
  }

  get hasUserMediaAPI (): boolean {
    return 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
  }

  addViewer (id: string, name: string): void {
    // Generating TURN credential then creating Peer Connection
    fetch(`${process.env.VUE_APP_WEBRTC_URL}/turn`, {
      headers: {
        Authorization: 'Bearer ' + this.token
      },
      method: 'GET'
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 401) {
          window.location.reload()
        }
      })
      .then((turnCredentials: { password: string, username: string }) => {
        this.removeViewerIfExists(id)
        this.viewers.push({
          id,
          isCameraEnabled: false,
          name,
          pc: this.createPeerConnection(
            id,
            turnCredentials.username,
            turnCredentials.password
          )
        })
      })
  }

  allowViewer (id: string, content: string): void {
    this.addViewer(id, content)
    this.removeNotificationIfExists(id)
    this.sendMessage({
      message_type: MESSAGE_TYPE.ASK,
      content: true
    }, id)
  }

  clearLocalMedia (): void {
    this.localMedia = null

    if (this.$refs.localMediaEl) {
      const localMediaEl = this.$refs.localMediaEl as HTMLVideoElement
      localMediaEl.srcObject = this.localMedia
      localMediaEl.pause()
    }
  }

  clearLocalDisplayMedia (): void {
    this.displayMediaId = null
    this.localDisplayMedia = null

    if (this.$refs.localDisplayMediaEl) {
      const localDisplayMediaEl = this.$refs.localDisplayMediaEl as HTMLVideoElement
      localDisplayMediaEl.srcObject = this.localDisplayMedia
      localDisplayMediaEl.pause()
    }
  }

  async createAndSendOffer (id: string, pc: RTCPeerConnection): Promise<void> {
    const offer = await pc.createOffer()
    await pc.setLocalDescription(offer)

    this.sendMessage({
      message_type: MESSAGE_TYPE.SDP,
      content: offer
    }, id)
  }

  createPeerConnection (id: string, username: string, password: string): RTCPeerConnection {
    const pc = new RTCPeerConnection({
      // iceTransportPolicy: 'relay',
      iceServers: [{
        credential: password,
        urls: process.env.VUE_APP_TURN_URL,
        username
      }]
    })

    pc.onnegotiationneeded = async () => {
      await this.createAndSendOffer(id, pc)
    }

    pc.onicecandidate = (iceEvent) => {
      if (iceEvent && iceEvent.candidate) {
        this.sendMessage({
          message_type: MESSAGE_TYPE.CANDIDATE,
          content: iceEvent.candidate
        }, id)
      }
    }

    pc.ontrack = (event) => {
      const viewer = this.viewers.find(viewer => viewer.pc === event.target)

      if (viewer) {
        if (viewer.userMedia) {
          viewer.userMedia.addTrack(event.track)
        } else {
          const stream = event.streams[0] as MediaStream
          const viewerMediaEl = (this.$refs[viewer.id] as Array<HTMLVideoElement>)[0]
          const localDisplayMediaEl = this.$refs.localDisplayMediaEl as HTMLVideoElement

          stream.onremovetrack = (e) => {
            if (viewer.userMedia) {
              e.track.enabled = false
              e.track.stop()
              viewer.userMedia.removeTrack(e.track)
              viewerMediaEl.srcObject = viewer.userMedia

              if (localDisplayMediaEl.srcObject === viewerMediaEl.srcObject) {
                this.localDisplayMedia = viewer.userMedia
                localDisplayMediaEl.srcObject = this.localDisplayMedia
              }

              if (e.track.kind === 'video') {
                viewer.isCameraEnabled = false
              }

              if (viewer.userMedia.getTracks().length === 0 && viewer.userMedia !== this.localDisplayMedia) {
                viewer.userMedia = undefined
              }
            }
          }

          viewer.userMedia = stream
          viewerMediaEl.srcObject = viewer.userMedia
          if (!localDisplayMediaEl.srcObject) {
            this.localDisplayMedia = viewer.userMedia
            localDisplayMediaEl.srcObject = this.localDisplayMedia
          }
        }

        if (event.track.kind === 'video') {
          viewer.isCameraEnabled = true
        }
      }
    }

    pc.onconnectionstatechange = () => {
      if (['closed', 'disconnected', 'failed'].includes(pc.connectionState)) {
        this.removeViewerIfExists(id)
      }
    }

    this.localMedia?.getTracks()
      .forEach(track => pc.addTrack(
        track,
        this.localMedia as MediaStream
      ))

    if (this.localDisplayMedia) {
      this.localDisplayMedia.getTracks()
        .forEach(track => pc.addTrack(
          track,
          this.localDisplayMedia as MediaStream
        ))
    }

    return pc
  }

  async createSession (kind?: SESSION_KIND): Promise<void> {
    this.askingPermissions = true

    // Getting display media
    try {
      if (kind === SESSION_KIND.VIDEOCALL) {
        await this.toggleAll()
      } else if (kind === SESSION_KIND.CALL) {
        await this.toggleMicrophone()
      } else if (kind === SESSION_KIND.SCREENSHARE) {
        await this.toggleScreensharing()
      }

      // Subcribring to SSE
      if (!this.signaling) {
        this.initSignaling()
      }

      this.askingPermissions = false
    } catch (e) {
      this.askingPermissions = false
      throw e
    }
  }

  mounted (): void {
    if (this.isReady) {
      if (this.isKindAudiocall) {
        this.createSession(SESSION_KIND.CALL)
      } if (this.isKindVideocall) {
        this.createSession(SESSION_KIND.VIDEOCALL)
      } else if (this.isKindScreensharing) {
        this.createSession(SESSION_KIND.SCREENSHARE)
      }
    }
  }

  denyViewer (id: string): void {
    this.sendMessage({
      message_type: MESSAGE_TYPE.ASK,
      content: false
    }, id)
    this.removeNotificationIfExists(id)
  }

  destroySession (): void {
    if (this.localMedia) {
      this.toggleCamera(false)
      this.toggleMicrophone(false)
      this.clearLocalMedia()
      this.clearLocalDisplayMedia()
    }

    if (this.localDisplayMedia) {
      this.toggleScreensharing(false)
      this.clearLocalDisplayMedia()
    }

    if (this.viewers.length) {
      this.viewers.forEach(viewer => viewer.pc.close())
      this.viewers = []
    }

    if (this.signaling) {
      this.signaling.close()
      this.signaling.removeEventListener('json', this.signalHandler)
      this.signaling.removeEventListener('closed', this.destroySession)
      this.signaling = null
    }

    if (this.kind !== null) {
      this.$nextTick(() => {
        window.close()
      })
    }
  }

  getPeerConnection (id: string): RTCPeerConnection | undefined {
    return this.viewers.find(viewer => viewer.id === id)?.pc
  }

  initSignaling (): void {
    this.signaling = new EventSource(`${process.env.VUE_APP_WEBRTC_URL}/sse?kind=webrtc&feature=${this.kind}&token=${this.token}`)
    this.signaling.addEventListener('json', this.signalHandler)
    this.signaling.addEventListener('closed', this.destroySession)
  }

  isUserMediaEnabled (kind: string): boolean {
    return Boolean(this.userMediaEnabled[kind])
  }

  pingScreen ({ x, y }: { x: number, y: number}): void {
    const bbox = (this.$refs.localDisplayMediaEl as HTMLVideoElement).getBoundingClientRect()
    const ping = this.$refs.ping as HTMLElement

    if (!ping) {
      return
    }

    clearTimeout(this.pingTimeout)

    ping.style.left = x * bbox.width + bbox.left + 'px'
    ping.style.top = y * bbox.height + bbox.top + 'px'

    this.pingTimeout = setTimeout(() => {
      this.pingTimeout = 0
    }, 3000)
  }

  removeNotificationIfExists (id: string): void {
    const index = this.notifications.findIndex(n => n.id === id)
    if (index >= 0) {
      this.notifications.splice(index, 1)
    }
  }

  removeViewerIfExists (id: string): void {
    const index = this.viewers.findIndex(viewer => viewer.id === id)
    const localDisplayMediaEl = this.$refs.localDisplayMediaEl as HTMLVideoElement

    if (index >= 0) {
      const viewer = this.viewers[index]

      if (viewer.userMedia === this.localDisplayMedia) {
        this.localDisplayMedia = null
        localDisplayMediaEl.srcObject = this.localDisplayMedia
        localDisplayMediaEl.pause()
      }

      viewer.pc.close()
      this.viewers.splice(index, 1)
    }
  }

  sendMessage (message: { content: unknown, 'message_type': string }, to: string): void {
    fetch(`${process.env.VUE_APP_WEBRTC_URL}/sessions/${to}`, {
      body: JSON.stringify(message),
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
  }

  async signalHandler (message: Event): Promise<void> {
    const data = JSON.parse((message as any).data)

    if (!data) {
      return
    }

    const from = data.from
    const { content, message_type: messageType } = data.payload
    const pc = this.getPeerConnection(from)

    if (messageType === MESSAGE_TYPE.CANDIDATE && content && pc) {
      await pc.addIceCandidate(content)
    } else if (messageType === MESSAGE_TYPE.SDP && pc) {
      if (content.type === 'offer') {
        await pc.setRemoteDescription(content)
        const answer = await pc.createAnswer()
        await pc.setLocalDescription(answer)
        this.sendMessage({
          message_type: MESSAGE_TYPE.SDP,
          content: answer
        }, from)
      } else if (content.type === 'answer') {
        await pc.setRemoteDescription(content)
      }
    } else if (messageType === MESSAGE_TYPE.ASK) {
      this.removeNotificationIfExists(from)
      this.notifications.push({
        content,
        id: from
      })
    } else if (messageType === MESSAGE_TYPE.PING) {
      this.pingScreen(content)
    }
  }

  async switchCamera (): Promise<void> {
    if (!this.isCameraEnabled) {
      return
    }

    const facingMode = this.userMediaEnabled.video === 'user'
      ? 'environment'
      : 'user'

    await this.toggleCamera(false)
    await this.toggleCamera(true, facingMode)
  }

  async toggleAll (): Promise<void> {
    await this.toggleUserMedia(null)
  }

  async toggleCamera (force?: boolean, facingMode?: 'user' | 'environment'): Promise<void> {
    await this.toggleUserMedia('video', force, facingMode)
  }

  async toggleMicrophone (force?: boolean): Promise<void> {
    await this.toggleUserMedia('audio', force)
  }

  async toggleUserMedia (kind: 'audio' | 'video' | null, force?: boolean, facingMode = 'user'): Promise<void> {
    const localMediaEl = this.$refs.localMediaEl as HTMLVideoElement
    const isUserMediaEnabled = kind ? this.isUserMediaEnabled(kind) : false

    if (force === false || isUserMediaEnabled) {
      this.viewers.forEach(viewer => {
        viewer.pc
          .getSenders()
          .forEach(sender => {
            if (sender.track?.kind === kind && sender.track?.id !== this.displayMediaId) {
              sender.track.enabled = false
              sender.track.stop()
              viewer.pc.removeTrack(sender)
            }
          })
      })

      this.localMedia?.getTracks().forEach(track => {
        if (track.kind === kind) {
          track.enabled = false
          track.stop()
          this.localMedia?.removeTrack(track)
        }
      })

      if (this.localMedia?.getTracks().length === 0) {
        this.clearLocalMedia()
      } else {
        localMediaEl.srcObject = this.localMedia
      }
    } else {
      let userMediaDesc

      switch (kind) {
        case 'audio':
          userMediaDesc = {
            audio: {
              echoCancellation: true,
              noiseSuppression: true
            }
          }
          break
        case 'video':
          userMediaDesc = {
            video: {
              facingMode
            }
          }
          break
        default:
          userMediaDesc = {
            audio: {
              echoCancellation: true,
              noiseSuppression: true
            },
            video: {
              facingMode
            }
          }
          break
      }

      const stream = await navigator.mediaDevices.getUserMedia(userMediaDesc)

      if (!this.localMedia) {
        this.localMedia = stream
        localMediaEl.srcObject = this.localMedia
      }

      stream
        .getTracks()
        .forEach(track => {
          if (this.localMedia !== stream) {
            this.localMedia?.addTrack(track)
          }

          if (!this.hasFacingMode && track.getCapabilities().facingMode?.length) {
            this.hasFacingMode = true
          }

          this.viewers.forEach(viewer => {
            viewer.pc.addTrack(track, this.localMedia as MediaStream)
          })
        })
    }

    const requestedKinds = kind
      ? [kind]
      : Object.keys(this.userMediaEnabled)

    requestedKinds.forEach(kind => {
      if (kind === 'video') {
        this.userMediaEnabled[kind] = !isUserMediaEnabled
          ? facingMode
          : false
      } else {
        this.userMediaEnabled[kind] = !isUserMediaEnabled
      }
    })
  }

  toggleOverlay (force?: boolean): void {
    this.showOverlay = force !== undefined
      ? force
      : !this.showOverlay

    if (this.showOverlay) {
      if (this.showOverlayTimeout) {
        clearTimeout(this.showOverlayTimeout)
      }

      this.showOverlayTimeout = setTimeout(() => {
        this.showOverlay = false
      }, 5000)
    }
  }

  async toggleScreensharing (force?: boolean): Promise<void> {
    const localDisplayMediaEl = this.$refs.localDisplayMediaEl as HTMLVideoElement
    const isEnabled = Boolean(this.displayMediaId)

    if (force === false || isEnabled) {
      this.viewers.forEach(viewer => {
        viewer.pc
          .getSenders()
          .forEach(sender => {
            if (sender.track?.id === this.displayMediaId) {
              sender.track.enabled = false
              sender.track.stop()
              viewer.pc.removeTrack(sender)
            }
          })
      })

      this.localDisplayMedia?.getTracks().forEach(track => {
        track.enabled = false
        track.stop()
        this.localDisplayMedia?.removeTrack(track)
      })

      this.clearLocalDisplayMedia()
    } else {
      const stream = await navigator.mediaDevices.getDisplayMedia()

      this.localDisplayMedia = stream
      localDisplayMediaEl.srcObject = this.localDisplayMedia

      stream
        .getTracks()
        .forEach(track => {
          this.viewers.forEach(viewer => {
            viewer.pc.addTrack(track)
          })
          this.displayMediaId = track.id
        })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/fonts/stylesheet.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: "Be Vietnam";
  font-size: .9rem;
  margin: 0;
}

button {
  font-family: "Be Vietnam";
  font-size: .9rem;

  &:hover {
    cursor: pointer;
  }
}

.material-design-icon {
  &,
  & > .material-design-icon__svg {
    height: 1em;
    line-height: 0;
    width: 1em;
  }
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
</style>

<style lang="scss" scoped>

$grey: #425466;
$darkblue: #05224F;
$green: #18CD97;
$red: #FB4D6B;

.hidden {
  visibility: hidden;
}

.webrtc {
  background-color: $grey;
  display: flex;
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  &.show-overlay {
    .webrtc-destroy {
      transform: translate(-50%, 0);
    }
    .webrtc-toggles {
      transform: translate(0, 0);
    }
    .viewers {
      transform: translateY(0);
    }
  }

  @media (hover: hover) {
    &:hover {
      .webrtc-destroy {
        transform: translate(-50%, 0);
      }
      .webrtc-toggles {
        transform: translate(0, 0);
      }
      .viewers {
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
}

.video {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;

  video {
    background-color: $darkblue;
    border-radius: 5px;
    display: block;
    max-height: 100%;
    max-width: 100%;
  }

  .icon {
    color: white;
    font-size: 5rem;
    left: 50%;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.local-media {
    bottom: 1rem;
    height: auto;
    justify-content: flex-end;
    max-width: 20%;
    min-width: 200px;
    padding: 0;
    position: absolute;
    right: 1rem;
    width: auto;
    z-index: 1;

    video {
      background-clip: padding-box;
      border: 1px solid transparent;
      min-height: 100px;

      @media (max-width: 800px) {
        max-height: 150px;
      }
    }

    .icon {
      font-size: 4rem;
    }
  }
}

.viewers-medias {
  background-color: rgba(0,0,0,.5);
  flex-shrink: 0;
  font-size: 0;
  max-width: 20%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0;
  text-align: center;

  & > * {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .video {
    padding: 0 10px;
  }

  @media (max-width: 800px) {
    max-height: 20%;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 10px;

    & > div {
      display: inline-flex;
      flex-direction: row;
      height: 100%;
    }

    .video {
      padding: 10px 0;
    }

    video {
      max-width: none;
    }
  }
}

.webrtc-create {
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  button {
    background-color: $darkblue;
    border-radius: 99px;
    border: none;
    color: white;
    font-weight: 500;
    margin: .5rem 0;
    padding: 1rem 2rem;
    transition: background-color .15s;

    .icon {
      font-size: 1rem;
      margin-right: .35rem;
    }

    &:hover {
      background-color: $green;
    }
  }
}

.webrtc-destroy {
  align-items: center;
  background-color: $red;
  border-radius: 25px;
  border: none;
  bottom: 1rem;
  color: white;
  display: flex;
  font-size: 2rem;
  height: 50px;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translate(-50%, calc(1rem + 50px));
  transition: background-color .15s, transform .15s;
  width: 50px;
  z-index: 4;

  &:hover {
    background-color: darken($color: $red, $amount: 10%);
  }
}

.webrtc-toggles {
  background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  position: fixed;
  right: 0;
  transform: translateY(calc(1rem + 100%));
  transition: transform .15s;
  width: 100%;
  z-index: 3;

  button {
    align-items: center;
    background-color: transparent;
    border-radius: 3px;
    border: none;
    bottom: 1rem;
    color: white;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    padding: 5px;
    transition: background-color .15s;
    width: 35px;

    & > * {
      pointer-events: none;
    }

    .icon {
      filter: drop-shadow(1px 1px 0 black);
    }

    &:hover {
      background-color: rgba($color: black, $alpha: .35);
    }
  }
}

.viewers {
  align-items: baseline;
  background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
  color: white;
  display: flex;
  flex-wrap: wrap;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  left: 0;
  padding: .5rem 1rem;
  position: absolute;
  text-shadow: 1px 1px 0px $darkblue;
  top: 0;
  transform: translateY(-100%);
  transition: transform .15s;
  width: 100%;

  .viewers-count {
    margin-right: 1rem;
    white-space: nowrap;

    .icon {
      font-size: 1.2rem;
      margin-right: .35rem;
    }

    :deep(svg) {
      filter: drop-shadow(1px 1px 0 $darkblue);
    }
  }

  .viewers-list {
    font-size: .85rem;
    font-weight: 500;
  }
}

.ping {
  animation: breathing 1s infinite linear;
  background-color: black;
  border-radius: 50%;
  filter: invert(1);
  max-width: 50px;
  min-width: 30px;
  mix-blend-mode: difference;
  opacity: 1;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: top .25s, left .25s;
  width: 3.5%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

@keyframes breathing {
  0% {
    transform: translate(-50%, -50%) scale(1)
  }
  33% {
    transform: translate(-50%, -50%) scale(1.2)
  }
  66% {
    transform: translate(-50%, -50%) scale(.8)
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.notifications {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 20px;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 420px;
  z-index: 1;

  @media (max-width: 420px) {
    padding-left: 0;
    width: 100%;
  }

  & > * {
    margin: .5rem;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
}

.list-move, .list-enter-active, .list-leave-active {
  transition: opacity .25s, transform .25s;
}

.list-enter-from, .list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.list-leave-active {
  position: absolute;
}

.error {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
  position: absolute;
  text-align: center;
  top: 35%;
  width: 100%;
}
</style>
